import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Div100vh from "react-div-100vh";
import favicon from "../images/favicon.png";
import SvgOrb from "./Orb/svg-orb";
import classNames from "classnames";
import resume from "../resume.pdf"

const Layout = ({ pageTitle, children, flex, pathname }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />

        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
          integrity="sha512-9usAa10IRO0HhonpyAIVpjrylPvoDwiPUiKdWk5t3PyolY1cOd4DSE0Ga+ri4AuTroPR5aQvXU9xC6qOPnzFeg=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <title>
          {pageTitle} | {data.site.siteMetadata.title}
        </title>
      </Helmet>

      <Div100vh
        id="main"
        className={classNames(" mx-auto safari-only", {
          "flex flex-col justify-between ": flex,
        })}
      >
        <nav className=" px-2 sm:px-4 py-2.5 ">
          <div className="container flex flex-wrap justify-center md:justify-between items-center mx-auto">
            <Link to="/" className="hidden md:flex">
              <SvgOrb />
              <span className="self-center my-3 text-lg font-semibold whitespace-nowrap text-slate-200">
                {data.site.siteMetadata.title}
              </span>
            </Link>

            <Link
              to="/"
              className={pathname === "/" ? "text-cyan-500" : "text-slate-500"}
            >
              <i className="fas fa-home fa-xl p-2 mx-2 hover:text-cyan-500" />
            </Link>
          </div>
        </nav>
        <main>{children}</main>

        <footer className="p-5 mt-6 flex justify-center items-center">
          <a
            href="https://www.github.com/ciscoducasse99"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-github fa-xl text-cyan-500 p-4 mx-2 hover:text-cyan-200" />
          </a>
          <a
            href="mailto:ciscoducasse99@hotmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-solid fa-envelope fa-xl text-cyan-500 p-4 mx-2 hover:text-cyan-200" />
          </a>
          <a
            href="https://www.linkedin.com/in/francisco-ducasse-65422615a"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin-in fa-xl text-cyan-500 p-4 mx-2 hover:text-cyan-200" />
          </a>
          <a
            href={resume}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-solid fa-file fa-xl text-cyan-500 p-4 mx-2 hover:text-cyan-200" />
          </a>
        </footer>
      </Div100vh>
    </>
  );
};

export default Layout;
